import React from "react";
import { graphql } from "gatsby";
import "./page.template.scss";
import SEO from "components/seo/seo";
import { pageContextProps } from "utils/props.utils";

interface Props {
  pageContext: pageContextProps;
  data: {
    page: {
      title: string;
      content: string;
    };
  };
}

const PageTemplate: React.FC<Props> = (props) => {
  return (
    <div className="page-template">
      <SEO {...props.pageContext.seo} />
      <div className="page-template__wrapper">
        <div className="page-template__content">
          {props.data.page.title === "Full-Stack Developer" && (
            <p style={{ marginBottom: 0 }}>We are hiring</p>
          )}
          <h1 style={{ marginTop: "0px" }}>{props.data.page.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: props.data.page.content }} />
        </div>
      </div>
    </div>
  );
};

export default PageTemplate;

// export { Head } from "components/head/head";

export const query = graphql`
  query ($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      title
      content
    }
  }
`;
